import MuiTextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, useField } from 'react-final-form';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { makeStyles } from '@mui/styles';

import {
  compose,
  maxNumber,
  minNumber,
  required
} from '../utils/form/validators';
import Label from './Label';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: props => (props.fullWidth ? '100%' : 'auto')
  },
  muiFieldWrapper: {
    width: props =>
      props.fullWidth
        ? '100%'
        : `${
            1.5 + props.digits * (props.mode === 'currency' ? 2.5 * 0.57 : 0.57)
          }em`
  },
  muiTextField: {
    '& .MuiOutlinedInput-input': {
      padding: '.625em .75em',
      fontSize: theme.font?.size?.input
    },
    '& .Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.color.error.main
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.color.border.main
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.color.primary.main
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.color.border.light
    },
    '& .Mui-disabled.MuiOutlinedInput-input': {
      textFillColor: theme.color.text.light
    },
    '& .MuiFormHelperText-root': {
      margin: 0
    },
    ...(theme.components?.textField?.root
      ? theme.components?.textField?.root
      : {})
  }
}));

const ReactNumberFormatComponent = props => {
  if (props.settings?.formatProps?.format) {
    return <PatternFormat {...props} />;
  } else {
    return <NumericFormat {...props} />;
  }
};

const CustomNumberFormat = React.forwardRef(
  function CustomNumberFormat(props, ref) {
    const { onChange, ...other } = props;

    return (
      <ReactNumberFormatComponent
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          const formatedValue =
            props.settings.mode === 'number'
              ? Number(values.value)
              : values.value;

          onChange({
            target: {
              name: props.name,
              value: values.value.length ? formatedValue : null
            }
          });
        }}
        {...props.settings.formatProps}
        valueIsNumericString
        prefix={props.settings.prefix}
        suffix={props.settings.suffix}
        allowNegative={props.settings.allowNegative}
        decimalScale={props.settings.decimalScale}
        decimalSeparator={props.settings.decimalSeparator}
        thousandSeparator={props.settings.thousandSeparator}
        allowLeadingZeros={props.settings.allowLeadingZeros}
      />
    );
  }
);

const NumberField = React.forwardRef(function NumberField(props, ref) {
  const classes = useStyles(props);
  const field = useField(props.id);
  const [value, setValue] = useState(
    (props.value && String(props.value)) || field.input.value
  );

  useEffect(() => {
    setValue(field.input.value);
  }, [field.input.value]);

  function handleChange(v, e) {
    setValue(v);

    if (!props.validateOnBlur) {
      if (v || typeof v !== 'object') {
        field.input.onChange(v);
      } else {
        field.input.onChange(null);
      }

      if (props.onChange) props.onChange(v, e);
    }
  }

  function handleBlur(e) {
    field.input.onBlur();

    if (props.onBlur) props.onBlur(e);

    if (props.validateOnBlur) {
      field.input.onChange(value);

      if (props.onChange) props.onChange(value);
    }
  }

  function handleFocus() {
    field.input.onFocus();

    if (props.onFocus) props.onFocus();
  }

  function getValidators() {
    const validators = [];

    if (props.required) validators.push(required);
    if (props.min && value) validators.push(minNumber(props.min));
    if (props.max && value) validators.push(maxNumber(props.max));

    return validators;
  }

  return (
    <Field
      name={props.id}
      validate={compose(getValidators())}
      validateFields={props.validateFields || []}
    >
      {({ meta }) => {
        const error = (meta.error || meta.submitError) && meta.touched;
        const errorText = meta.touched ? meta.error || meta.submitError : '';

        return (
          <div className={classes.wrapper}>
            {props.label ? (
              <Label
                for={props.id}
                disabled={props.disabled}
                required={props.required && !props.requiredWithoutAsterisk}
                popover={{
                  title: props.popoverTitle,
                  text: props.popoverText
                }}
              >
                {props.label}
              </Label>
            ) : null}
            <MuiTextField
              className={classes.muiTextField}
              error={props.error || error}
              helperText={
                props.errorExternalRendering
                  ? null
                  : props.errorText || errorText || props.helperText
              }
              FormHelperTextProps={{
                'aria-label': props.errorText || errorText || props.helperText
              }}
              inputProps={{
                'aria-label': props.ariaLabel
              }}
              InputProps={{
                className: classes.muiFieldWrapper,
                inputComponent: CustomNumberFormat,
                inputProps: {
                  maxLength: props.maxDigits || props.digits,
                  settings: {
                    allowNegative: props.allowNegative,
                    formatProps: props.formatProps,
                    mode: props.mode,
                    prefix: props.prefix,
                    suffix: props.suffix,
                    decimalScale: props.decimalScale,
                    decimalSeparator: props.decimalSeparator,
                    thousandSeparator: props.thousandSeparator,
                    allowLeadingZeros: props.allowLeadingZeros
                  },
                  'aria-labelledby': props['aria-labelledby'],
                  'aria-describedby':
                    props.errorText || errorText || props.helperText
                      ? props['aria-describedby'] === undefined
                        ? `${props.id}-helper-text`
                        : props['aria-describedby']
                      : null
                },
                notched: false
              }}
              name={props.id}
              InputLabelProps={{
                shrink: true,
                variant: 'standard',
                disableAnimation: true,
                required:
                  props.required &&
                  !props.popoverText &&
                  !props.requiredWithoutAsterisk
              }}
              inputRef={ref}
              id={props.id}
              autoComplete="off"
              autoFocus={props.autoFocus}
              disabled={props.disabled}
              variant="outlined"
              onChange={e => handleChange(e.target.value, e)}
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder={props.placeholder}
              fullWidth={props.fullWidth}
              value={value}
              type={props.type}
            />
          </div>
        );
      }}
    </Field>
  );
});

NumberField.propTypes = {
  'aria-labelledby': PropTypes.string,
  'aria-describedby': PropTypes.string,
  allowLeadingZeros: PropTypes.bool,
  allowNegative: PropTypes.bool,
  ariaLabel: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  decimalScale: PropTypes.number,
  decimalSeperator: PropTypes.string,
  digits: PropTypes.number,
  disabled: PropTypes.bool,
  endIcon: PropTypes.string,
  error: PropTypes.bool,
  errorExternalRendering: PropTypes.bool,
  errorText: PropTypes.node,
  // https://github.com/s-yadav/react-number-format#props
  formatProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  max: PropTypes.number,
  maxDigits: PropTypes.number,
  min: PropTypes.number,
  mode: PropTypes.oneOf(['number', 'string', 'currency']),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  requiredWithoutAsterisk: PropTypes.bool,
  thousandSeparator: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'password']),
  validateOnBlur: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

NumberField.defaultProps = {
  'aria-labelledby': undefined,
  'aria-describedby': undefined,
  allowLeadingZeros: false,
  allowNegative: true,
  ariaLabel: '',
  autoComplete: undefined,
  autoFocus: false,
  decimalScale: undefined,
  decimalSeparator: ',',
  digits: 8,
  disabled: false,
  endIcon: undefined,
  error: false,
  errorExternalRendering: false,
  errorText: undefined,
  formatProps: {},
  fullWidth: false,
  helperText: undefined,
  label: undefined,
  max: undefined,
  maxDigits: undefined,
  min: undefined,
  mode: 'number',
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  placeholder: undefined,
  required: false,
  requiredWithoutAsterisk: false,
  thousandSeparator: false,
  type: 'text',
  validateOnBlur: false,
  value: undefined
};

export default NumberField;
