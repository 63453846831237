import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import Icon from './Icon';
import Text from './Text';

const useStyles = makeStyles(theme => ({
  message: {
    background: props => {
      switch (props.type) {
        default:
        case 'default':
          return theme.color.primary.light;
        case 'error':
          return theme.color.error.light;
        case 'success':
          return theme.color.success;
        case 'warning':
          return theme.color.warning.light;
      }
    },
    boxSizing: 'border-box',
    whiteSpace: 'pre-line',
    width: '100%',
    '& svg': {
      flexShrink: 0
    },
    ...(theme.components?.message?.root ? theme.components?.message?.root : {})
  },
  text: {
    ...(theme.components?.message?.text ? theme.components?.message?.text : {})
  }
}));

export default function Message(props) {
  const classes = useStyles(props);

  return (
    <Box
      className={classes.message}
      display="flex"
      p={1}
      role="alert"
      data-testid={props['data-testid']}
    >
      {props.type !== 'default' && (
        <Icon color="default" name="info-circle" size="large" />
      )}
      <Box ml={1} mt={0.5}>
        <Text className={classes.text}>{props.children}</Text>
      </Box>
    </Box>
  );
}

Message.propTypes = {
  type: PropTypes.oneOf(['default', 'error', 'warning', 'success']),
  'data-testid': PropTypes.string,
  children: PropTypes.node
};

Message.defaultProps = {
  type: 'default',
  children: ''
};
